var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"app-tabs-content"},[(_vm.loaded)?_c('div',[(_vm.results.error)?_c('div',{staticClass:"container"},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("\n                "+_vm._s(_vm.results.error)+"\n            ")]),_vm._v(" "),_c('section',{staticClass:"bg-light-blue",attrs:{"id":"search-form-large"}},[_c('search-form')],1)],1):(_vm.results.sentences.length)?_c('div',[_c('header',{staticClass:"app-tabs-nav"},[_c('div',{staticClass:"container position-relative"},[_c('div',{staticClass:"d-flex flex-row"},[_c('SearchForm',{attrs:{"loading":_vm.loading}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row",class:{'justify-content-around': _vm.$root.isMobile}},[_c('select-box',{staticClass:"align-self-start",attrs:{"expand":"","label":"Show","selected":_vm.currentRoute,"options":{
                                     results: 'Pro/con' ,
                                     results_list: 'List',
                                     results_documents: 'Documents' }},on:{"changed":_vm.navigateTab}}),_vm._v(" "),_c('select-box',{staticClass:"ml-lg-auto",attrs:{"label":"Subjects","selected":"","multiple":"","options":_vm.results.subjects},on:{"changed":_vm.setSubjects}}),_vm._v(" "),_c('select-box',{staticClass:"ml-lg-3",attrs:{"label":"Source","selected":"","multiple":"","options":_vm.results.sources},on:{"changed":_vm.setFilters}}),_vm._v(" "),_c('select-box',{staticClass:"ml-lg-3",attrs:{"label":"Sort By","selected":_vm.sortStrategy,"options":{
                                    confidence: 'Confidence' ,
                                    timestamp: 'Date'
                                 }},on:{"changed":_vm.setSortStrategy}})],1)])]),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"search-info"},[_c('strong',[_vm._v("Found "+_vm._s(_vm.results.metadata.totalArguments)+" arguments")]),_vm._v("\n                            ("+_vm._s(_vm.results.metadata.totalProArguments)+" pro;\n                            "+_vm._s(_vm.results.metadata.totalContraArguments)+" con)\n                            "),_c('strong',[_vm._v("in "+_vm._s(_vm.results.metadata.numDocs)+" documents")]),_vm._v("\n                            (classified "+_vm._s(_vm.results.metadata.totalClassifiedSentences)+" sentences in\n                            "+_vm._s(_vm._f("round")(_vm.results.metadata.timeTotal))+" s)\n                        ")])])]),_vm._v(" "),_c('router-view',{attrs:{"results":_vm.results,"id":"results"}})],1)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }