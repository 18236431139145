<template>
    <li class="card sentence"
        :class="sentence.stanceLabel">
        <article>
            <div class="card-header">
                <span :class="sentence.stanceLabel"
                      class="label">{{ sentence.stanceLabelDisplay }}</span>
                <small>
                    <a :href="sentence.pdfmeta" target="_blank">
                        <span class="source">{{ sentence.header | dropwww }}</span>
                    </a>
                    <a><i class="icon-slash-1"></i></a>
                    <a :href="sentence.pdf" target="_blank">
                        <span class="source">pdf</span>
                    </a>
                    <a><i class="icon-slash-1"></i></a>
                    <a>
                        <time :datetime="sentence.timestamp">{{sentence.timestamp | formatDate}}</time>
                    </a>
                        
                </small>
            </div>
            <div class="card-body">
                <p>
                    <span class="sentence" v-html="content"></span>
                    <small title="Confidence score"> {{sentence.sortConfidence*100 | round}}%</small>
                </p>
                <div class="subjects">
                    <span class="badge-pill subject" :class="key" v-for="subject, key in subjects">{{subject}}</span>
                </div>
            </div>
        </article>
    </li>
</template>

<script>
    import { subjects } from '../../api/subjects';
    export default {
        props: [
            'sentence',
            'content',
        ],
        name: "SearchResultCard",
        computed:{
            subjects: function () {
                const subjectNames = {};
                this.sentence.subjects.forEach((subject) => {
                    if (subjects[subject]) {
                        subjectNames[subject] = subjects[subject];
                    }
                });
                return subjectNames;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";

    small {
        color: $gray;
        font-family: Signika, sans-serif;
        font-size: 0.8rem !important;
        letter-spacing: 0;

        @include media-breakpoint-up(lg) {
            font-size: 0.9rem !important;
        }


        a > i {
            color: $yellow;
            font-size: 0.6875rem;
            margin: 1px;

            @include media-breakpoint-up(lg) {
                font-size: 0.8125rem;
            }
        }

        a, a:visited, a:focus, a:active, a > time {
            color: #b0bcc6;
        }

        a:hover {
            text-decoration: none !important;
        }

    }

    p > small {
        color: #b0bcc6;
    }

    .sentence {
        p:first-letter {
            text-transform: capitalize;
        }
    }

    .subjects {
        display: flex;
        flex-wrap: wrap; /* This allows the items to wrap onto multiple lines */
        .subject {
            font-family: Signika, sans-serif;
            font-size:1rem;
            margin-right: 0.5rem;
            margin-top: 0.5rem;
            white-space: nowrap;
            color: #fff;
        }

        .fb01 { background-color: #005AA9; }
        .fb02 { background-color: #732054; }
        .fb03 { background-color: #A87F00; }
        .fb04 { background-color: #243572; }
        .fb05 { background-color: #005AA9; }
        .fb07 { background-color: #CC4C03; }
        .fb10 { background-color: #6A8B22; }
        .fb11m { background-color: #008877; }
        .fb11g { background-color: #008877; }
        .fb13 { background-color: #961C26; }
        .fb15 { background-color: #A87F00; }
        .fb16 { background-color: #004E73; }
        .fb18 { background-color: #00689D; }
        .fb20 { background-color: #004E73; }
        .fb00 { background-color: #6c757d; }
    }

    .card {
        // max-width: 1035px;
        margin: 0 0 10px 0;
        border-radius: 0;
        border: 0 !important;
        -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);

        transition: height 1s ease-out;

        @include media-breakpoint-up(md) {
            margin: 0 0 16px 0;
        }

        .card-header {
            background-color: transparent;
            position: relative;
            border-bottom: 3px solid #F4F9FB;
            padding: 0.25rem 0.75rem;

            @include media-breakpoint-up(lg) {
                padding: 0.75rem 1.25rem;
            }

            &:after {
                content: '';
                display: table;
                clear: both;
            }

            small {
                /* position: absolute;
                right: 1.25rem; */
                display: block;
                @include float-right;
                max-width: 90%;
                overflow: hidden;
                // white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 24px;
                text-align: right;
            }

            .label {
                font-size: 13px;
                line-height: 13px;

                @include media-breakpoint-up(lg) {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
        }

        .card-body {

            padding: 0.75rem 0.75rem;

            @include media-breakpoint-up(lg) {
                padding: 1.25rem;
            }

            p {
                color: $gray;
                margin: 0;
                font-size: .85rem;
                font-weight: 400;

                @include media-breakpoint-up(lg) {
                font-size: 1rem;
                line-height: 26px;
            }
            }
        }
    }
</style>
