const navigatorLanguage = navigator.language.slice(0, 2);

const state = {
    language: 'en',
    topic: '',
    sortStrategy: 'argumentConfidence',
    searchFilters: [],
    searchSubjects: [],
    filterSubjects: [],
    strict: false
};

// getters
const getters = {
    getLanguage() {
        return state.language;
    },
    getTopic() {
        return state.language;
    },
    getSearchSubjects() {
        return state.searchSubjects;
    },
    getFilterSubjects() {
        return state.filterSubjects;
    }
};

// actions
const actions = {
};

// mutations
const mutations = {
    /* eslint-disable no-shadow */
    updateLanguage(state, language) {
        /* eslint-disable no-param-reassign */
        state.language = language;
    },
    /* eslint-disable no-shadow */
    updateTopic(state, topic) {
        /* eslint-disable no-param-reassign */
        state.topic = topic;
    },
    /* eslint-disable no-shadow */
    updateSearchSubjects(state, searchSubjects) {
        /* eslint-disable no-param-reassign */
        state.searchSubjects = searchSubjects;
    },
    updateFilterSubjects(state, filterSubjects) {
        state.filterSubjects = filterSubjects;
    },
    /* eslint-disable no-shadow */
    updateSortStrategy(state, sortStrategy) {
        /* eslint-disable no-param-reassign */
        state.sortStrategy = sortStrategy;
    },
    /* eslint-disable no-shadow */
    updateSearchFilters(state, searchFilters) {
        /* eslint-disable no-param-reassign */
        state.searchFilters = searchFilters;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
